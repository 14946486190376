<template>
  <div>
    <el-form
      :model="selectedDocument"
      ref="formedForm"
      :rules="rules"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="Наименование" prop="name">
            <el-input v-model="selectedDocument.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="Номер" prop="number">
            <el-input v-model="selectedDocument.number">
              <template slot="prepend">№</template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="Дата" prop="date">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd"
              v-model="selectedDocument.date"
              type="date"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <p>
          <el-col :span="24">
            Добавленный документ: {{ getFileName }}
            <el-button
              @click="deleteDocument"
              v-if="selectedDocument.filePath !== null"
              type="danger"
              size="mini"
              >Удалить</el-button
            >
          </el-col>
        </p>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="24">
          <el-upload
            v-if="selectedDocument.filePath === null"
            style="text-align: center"
            drag
            :limit="1"
            :on-change="changeFile"
            :auto-upload="false"
            v-model="selectedDocument.file"
            action=""
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Перетащите файл сюда или <em>нажмите для загрузки</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              .pdf, .xls/.xlsx, .img, .jpg, .png, .doc/.docx файлы размером не
              больше 20 Мб
            </div>
          </el-upload>
        </el-col>
      </el-row>
      <el-button type="success" @click="save">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "administrativeDocumentEdit",
  props: ["selectedDocument"],
  data() {
    return {
      file: "",
      rules: {
        name: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        number: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "Заполните поле",
            trigger: "blur",
          },
        ],
      },
    };
  },

  computed: {
    getFileName: {
      get() {
        return this.selectedDocument.filePath !== null
          ? this.selectedDocument.filePath.slice(
              this.selectedDocument.filePath.lastIndexOf("\\") + 1
            )
          : "Документ не загружен";
      },
    },
  },

  methods: {
    deleteDocument() {
      this.selectedDocument.filePath = null;
    },

    changeFile(file, fileList) {
      console.log(fileList);
      this.file = file.raw;
    },

    save() {
      let document = new FormData();
      document.append("docId", this.selectedDocument.docId);
      document.append("name", this.selectedDocument.name);
      document.append("number", this.selectedDocument.number);
      document.append("date", this.selectedDocument.date);
      document.append("filePath", this.selectedDocument.filePath);
      document.append("file", this.file);

      this.$store
        .dispatch("updateAdministrativeDocument", document)
        .then((response) => {
          console.log(response);
          this.notification("Успешно", "Документ изменен", "success");
          this.$store.dispatch("getAllAdministrativeDocument", {
            page: 0,
            size: 20,
          });
          this.$store.dispatch("setShowModal", null);
        });
    },
  },
};
</script>

<style scoped></style>
