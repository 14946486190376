<template>
  <div>
    <administrative-document-edit></administrative-document-edit>
  </div>
</template>

<script>
import AdministrativeDocumentEdit from "@/components/ecologist/classifiers/administrativeDocument/administrativeDocumentEdit";
export default {
  name: "administrativeDocumentEditView",
  components: { AdministrativeDocumentEdit },
};
</script>

<style scoped></style>
